<template>
  <div>
    <!-- Surface -->
    <b-row class="mb-2">
      <b-col>
        <NumericInput
                id="yield"
                inputRef="input"
                :value="parseFloat(surface)"
                @input="i => {surface = i}"
                :disabled="fromActivity" 
                v-b-tooltip.hover
                :title="fromActivity ? $t('yield.details.surface_cant_be_modified') : ''"
          />
      </b-col>
      <b-col v-if="fromActivity" cols="3" class="p-0">
        <img :src="editIcon" alt="icon edit action"
          v-b-tooltip.hover
          :title="$t('yield.details.modify_activity')"
          @click="() => goToActivityEdit()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import editIcon from "@/assets/images/pencil-edit-button.svg";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      surface: "",
      loadComponent: false,
      fromActivity: false,
      editIcon: editIcon,
    };
  },

  async mounted() {
    this.loadComponent = false;
    // En mode modification, on récupère le rendement courant
    if (this.$route.name === "yield.edit") {
      this.surface = this.currentEntry.surface;
      // On empêche la modification de la surface si le rendement est lié à une activité
      this.fromActivity = this.currentEntry.activity != null;
    }
    this.loadComponent = true;
  },

  methods: {
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

    /**
     * Affiche l'activité liée au rendement en mode édition
     */
    goToActivityEdit() {
      this.$router.push({ name: "activity.edit", params: {activity_id: this.currentEntry.activity} });
    }
  },
  computed: {
    cropField_id() {
      return this.currentEditedEntry.cropField;
    }
  },
  watch: {
    surface: {
      handler: async function () {
        await this.$store.dispatch(this.storeEditAction, { surface: this.surface || null});
      },
    },
    cropField_id: {
      // Lors de la sélection d'une parcelle,
      // si la surface du rendement n'est pas renseignée, on la préremplit avec la surface de la parcelle
      handler: async function() {
        if (!this.surface && this.cropField_id && !this.fromActivity) {
          let cropField = await this.$store.dispatch("cropfield/getCropfield", {id: this.cropField_id});
          if (cropField?.surface) {
            this.surface = cropField.surface;
          }
        }
      }
    }
  },
  components: {
    NumericInput
  }
};
</script>

<style lang="scss" scoped>
  img {
    width: 0.8em;
    cursor: pointer;
  }
</style>
